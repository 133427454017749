<template>
    <div class="empty-placeholder" :class="[{ 'bordered': bordered }, 'size-' + size]" data-test="empty-placeholder">
        <b-img v-if="imgSrc" center :src="imgSrc"></b-img>
        <h6 v-if="title" class="title">{{title|tr}}</h6>
        <p v-if="description" class="title">{{description|tr}}</p>
        <span>
            <slot></slot>
        </span>
    </div>
</template>

<script>

    export default {
        name: `e-empty-placeholder`,
        props: {
            title: { type: String, default: `Nothing here|Il n'y a rien ici` },
            description: { type: String },
            size: { type: String, default: `md` },
            imgSrc: { type: String, default: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/empty.png` },
            bordered: { type: Boolean }
        }
    }
</script>

<style lang="scss" scoped>
    .empty-placeholder {
        max-width: 500px;
        margin: 100px auto;
        text-align: center;

        h6 {
            font-weight: 500;
        }

        img {
            height: 120px;
            width: 120px;
            margin-bottom: 20px;
            object-fit: contain;
        }

        &.bordered {
            border: 2px dashed rgba(white, 0.2);
            border-radius: 8px;
            padding: 25px;
            max-width: 600px;
        }

        &.size-sm {
            margin: 35px auto;
        }

        @media(max-width: 576px) {
            margin-top: 50px;
        }
    }
</style>
